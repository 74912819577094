<template>
  <div id="carouselControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: index == 0 }"
        v-for="(b, index) in banner"
        :key="index"
      >
        <a :href="b.link">
          <img class="w-100 d-block" :src="b.src" alt="..." />
        </a>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon h1 text-light" aria-hidden="true">
        <i class="fa-solid fa-chevron-left"></i>
      </span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon h1 text-light" aria-hidden="true">
        <i class="fa-solid fa-chevron-right"></i>
      </span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: ['banner'],
};
</script>

<style scoped>
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none;
  color: black;
}
</style>
