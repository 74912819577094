const news = [
  { // 文章
    id: "41",
    title: "【第28屆中國美容博覽會】",
    time: "2024/05/16",
    link: "/news/41",
    src: require("@/assets/images/zh-TW/news/new_41.jpg"),
    component: () => import("@/components/news/News41.vue"),
    video: false,
  },
  { // 文章
    id: "40",
    title: "【第十二屆全國微創醫學美容大會】",
    time: "2024/05/16",
    link: "/news/40",
    src: require("@/assets/images/zh-TW/news/new_40.jpg"),
    component: () => import("@/components/news/News40.vue"),
    video: false,
  },
  { // 文章
    id: "39",
    title: "【第十七屆美沃斯國際醫學美容大會】",
    time: "2024/05/10",
    link: "/news/39",
    src: require("@/assets/images/zh-TW/news/new_39.jpg"),
    component: () => import("@/components/news/News39.vue"),
    video: false,
  },
  { // 文章
    id: "38",
    title: "【第十二屆全國雷射美容與臉部年輕化學術大會】",
    time: "2024/04/29",
    link: "/news/38",
    src: require("@/assets/images/zh-TW/news/new_38.jpg"),
    component: () => import("@/components/news/News38.vue"),
    video: false,
  },
  { // 文章
    id: "37",
    title: "【2024 AMWC】",
    time: "2024/03/19",
    link: "/news/37",
    src: require("@/assets/images/zh-TW/news/new_37.jpg"),
    component: () => import("@/components/news/News37.vue"),
    video: false,
  },
  { // 文章
    id: "36",
    title: "【2024 AAD】",
    time: "2024/03/15",
    link: "/news/36",
    src: require("@/assets/images/zh-TW/news/new_36.jpg"),
    component: () => import("@/components/news/News36.vue"),
    video: false,
  },
  { // 文章
    id: "35",
    title: "【第63屆國際美博會】",
    time: "2024/02/29",
    link: "/news/35",
    src: require("@/assets/images/zh-TW/news/new_35.jpg"),
    component: () => import("@/components/news/News35.vue"),
    video: false,
  },
  { // 文章
    id: "34",
    title: "【AMWC 2024】",
    time: "2024/01/24",
    link: "/news/34",
    src: require("@/assets/images/zh-TW/news/new_34.jpg"),
    component: () => import("@/components/news/News34.vue"),
    video: false,
  },
  { // 文章
    id: "33",
    title: "【IMCAS 2024】",
    time: "2024/01/24",
    link: "/news/33",
    src: require("@/assets/images/zh-TW/news/new_33.jpg"),
    component: () => import("@/components/news/News33.vue"),
    video: false,
  },
  { // 文章
    id: "32",
    title: "【第十六屆美沃斯大會】",
    time: "2023/12/18",
    link: "/news/32",
    src: require("@/assets/images/zh-TW/news/new_32.jpg"),
    component: () => import("@/components/news/News32.vue"),
    video: false,
  },
  { // 文章
    id: "31",
    title: "【2023 TDA臺灣皮膚科醫學會】",
    time: "2023/11/06",
    link: "/news/31",
    src: require("@/assets/images/zh-TW/news/new_31.jpg"),
    component: () => import("@/components/news/News31.vue"),
    video: false,
  },
  { // 文章
    id: "30",
    title: "【臺灣醫用雷射光電學會 2023秋季年會】",
    time: "2023/09/18",
    link: "/news/30",
    src: require("@/assets/images/zh-TW/news/new_30.jpg"),
    component: () => import("@/components/news/News30.vue"),
    video: false,
  },
  { // 文章
    id: "29",
    title: "【第62屆廣州國際美博會】",
    time: "2023/08/25",
    link: "/news/29",
    src: require("@/assets/images/zh-TW/news/new_29.jpg"),
    component: () => import("@/components/news/News29.vue"),
    video: false,
  },
  { // 文章
    id: "28",
    title: "【第十五屆美沃斯醫療與美容科技博覽會】",
    time: "2023/08/21",
    link: "/news/28",
    src: require("@/assets/images/zh-TW/news/new_28.jpg"),
    component: () => import("@/components/news/News28.vue"),
    video: false,
  },
  { // 影片
    id: "27",
    title: "【OBSERV 520x_全臉影像分析檢測儀 分享篇】",
    time: "2023/08/01",
    src: require("@/assets/images/zh-TW/news/new_27.jpg"),
    link: "https://www.youtube.com/embed/On229d8VTnY",
    video: true,
  },
  { // 文章
    id: "26",
    title: "【匯成醫美創新運營大會】",
    time: "2023/07/03",
    link: "/news/26",
    src: require("@/assets/images/zh-TW/news/new_26.jpg"),
    component: () => import("@/components/news/News26.vue"),
    video: false,
  },
  { // 文章
    id: "25",
    title: "【第61屆上海國際美博會】",
    time: "2023/06/12",
    link: "/news/25",
    src: require("@/assets/images/zh-TW/news/new_25.jpg"),
    component: () => import("@/components/news/News25.vue"),
    video: false,
  },
  { // 文章
    id: "24",
    title: "【第27屆CBE中國美容博覽會】",
    time: "2023/05/10",
    link: "/news/24",
    src: require("@/assets/images/zh-TW/news/new_24.jpg"),
    component: () => import("@/components/news/News24.vue"),
    video: false,
  },
  { // 影片
    id: "23",
    title: "【AMWC Asia-TDAC 2023 花絮】",
    time: "2023/05/10",
    src: require("@/assets/images/zh-TW/news/new_23.jpg"),
    link: "https://www.youtube.com/embed/SO5wT1mgSBY",
    video: true,
  },
  { // 文章
    id: "22",
    title: "【AMWC Asia-TDAC 2023 Workshop】",
    time: "2023/04/14",
    link: "/news/22",
    src: require("@/assets/images/zh-TW/news/new_22.jpg"),
    component: () => import("@/components/news/News22.vue"),
    video: false,
  },
  { // 文章
    id: "21",
    title: "【AMWC Asia-TDAC 2023】",
    time: "2023/04/10",
    link: "/news/21",
    src: require("@/assets/images/zh-TW/news/new_21.jpg"),
    component: () => import("@/components/news/News21.vue"),
    video: false,
  },
  { // 影片
    id: "20",
    title: "【2023 臺灣醫用雷射光電學會 花絮】",
    time: "2023/03/29",
    src: require("@/assets/images/zh-TW/news/new_20.jpg"),
    link: "https://www.youtube.com/embed/20txltA8zyk",
    video: true,
  },
  { // 影片
    id: "19",
    title: "【OBSERV 520x_全臉影像分析檢測儀 形象篇】",
    time: "2023/03/29",
    src: require("@/assets/images/zh-TW/news/new_19.jpg"),
    link: "https://www.youtube.com/embed/C1TWTekjMQk",
    video: true,
  },
  { // 文章
    id: "18",
    title: "【AMWC MONACO 2023】",
    time: "2023/03/27",
    link: "/news/18",
    src: require("@/assets/images/zh-TW/news/new_18.jpg"),
    component: () => import("@/components/news/News18.vue"),
    video: false,
  },
  { // 文章
    id: "17",
    title: "【2023 第十屆全國微創醫學美容大會】",
    time: "2023/03/27",
    link: "/news/17",
    src: require("@/assets/images/zh-TW/news/new_17.jpg"),
    component: () => import("@/components/news/News17.vue"),
    video: false,
  },
  { // 文章
    id: "16",
    title: "【2023 臺灣醫用雷射光電學會】",
    time: "2023/03/01",
    link: "/news/16",
    src: require("@/assets/images/zh-TW/news/new_16.jpg"),
    component: () => import("@/components/news/News16.vue"),
    video: false,
  },
  { // 外部連結
    id: "15",
    title: "【OBSERV®肌膚旅歷｜功能特色】",
    time: "2023/02/15",
    //description:
      //"OBSERV 協助全面洞察客戶臉部狀況，讓醫學美容顧問可藉由輪廓比例、膚質及毛孔狀況...等各功能，輕鬆掌握客戶面部優勢，為客戶提供多元評估依據。",
    src: require("@/assets/images/zh-TW/news/new_15.jpg"),
    link: "https://observ.com.tw/features/OBSERV520x_features.html",
    video: false,
  },
  { // 影片
    id: "14",
    title: "【OBSERV 520x 360º光源模式】",
    time: "2023/02/08",
    src: require("@/assets/images/zh-TW/news/new_14.jpg"),
    link: "https://www.youtube.com/embed/cEpfiZYWPuw",
    video: true,
  },
  { // 文章
    id: "13",
    title: "【AMWC NORTH AMERICA 2023】",
    time: "2023/02/07",
    link: "/news/13",
    src: require("@/assets/images/zh-TW/news/new_13.jpg"),
    component: () => import("@/components/news/News13.vue"),
    video: false,
  },
  { // 影片
    id: "12",
    title: "【2023 中國整形美容協會科學技術頒獎典禮】",
    time: "2023/02/06",
    src: require("@/assets/images/zh-TW/news/new_12.jpg"),
    link: "https://www.youtube.com/embed/-nXGWBftUvM",
    video: true,
  },
  { // 文章
    id: "11",
    title: "【IMCAS World Congress 2023】",
    time: "2023/01/26",
    link: "/news/11",
    src: require("@/assets/images/zh-TW/news/new_11.jpg"),
    component: () => import("@/components/news/News11.vue"),
    video: false,
  },
  { // 文章
    id: "10",
    title: "【ICDA 2022】",
    time: "2022/12/16",
    link: "/news/10",
    src: require("@/assets/images/zh-TW/news/new_10.jpg"),
    component: () => import("@/components/news/News10.vue"),
    video: false,
  },
  { // 文章
    id: "09",
    title: "【2022 WRG祛斑抗衰高峰巡迴論壇 (武漢站)】",
    time: "2022/09/27",
    link: "/news/09",
    src: require("@/assets/images/zh-TW/news/new_09.jpg"),
    component: () => import("@/components/news/News09.vue"),
    video: false,
  },
  { // 文章
    id: "08",
    title: "【2022 WRG祛斑抗衰高峰巡迴論壇(鄭州站)】",
    time: "2022/08/24",
    link: "/news/08",
    src: require("@/assets/images/zh-TW/news/new_08.jpg"),
    component: () => import("@/components/news/News08.vue"),
    video: false,
  },
  { // 文章
    id: "07",
    title: "【2022 美沃斯國際醫學美容大會】",
    time: "2022/07/19",
    link: "/news/07",
    src: require("@/assets/images/zh-TW/news/new_07.jpg"),
    component: () => import("@/components/news/News07.vue"),
    video: false,
  },
  { // 文章
    id: "06",
    title: "【IMCAS World Congress 2022】",
    time: "2022/06/03",
    link: "/news/06",
    src: require("@/assets/images/zh-TW/news/new_06.jpg"),
    component: () => import("@/components/news/News06.vue"),
    video: false,
  },
  { // 文章
    id: "05",
    title: "Workshop at AMWC 2022",
    time: "2022/05/07",
    link: "/news/05",
    src: require("@/assets/images/zh-TW/news/new_05.jpg"),
    //articleImg: require("@/assets/images/zh-TW/news/Article_Banner8.jpg"),
    component: () => import("@/components/news/News05.vue"),
    video: false,
  },
  { // 文章
    id: "04",
    title: "【AMWC MONACO 2022】",
    time: "2022/03/30",
    link: "/news/04",
    src: require("@/assets/images/zh-TW/news/new_04.jpg"),
    component: () => import("@/components/news/News04.vue"),
    video: false,
  },
  { // 文章
    id: "03",
    title: "【2022 第59屆廣州國際美博會】",
    time: "2022/03/10",
    link: "/news/03",
    src: require("@/assets/images/zh-TW/news/new_03.jpg"),
    component: () => import("@/components/news/News03.vue"),
    video: false,
  },
  { // 文章
    id: "02",
    title: "【Workshop at AMWC 2021】",
    time: "2021/05/02",
    link: "/news/02",
    src: require("@/assets/images/zh-TW/news/new_02.jpg"),
    component: () => import("@/components/news/News02.vue"),
    video: false,
  },
  { // 影片
    id: "01",
    title: "【AMWC 2021 Highlights】",
    time: "2021/05/01",
    src: require("@/assets/images/zh-TW/news/new_01.jpg"),
    link: "https://www.youtube.com/embed/PEOcCgG-_IY",
    video: true,
  },
  { // 影片
    id: "00",
    title: "【2021 臺灣醫用雷射光電學會】",
    time: "2021/03/27",
    src: require("@/assets/images/zh-TW/news/new_00.jpg"),
    link: "https://www.youtube.com/embed/9KMuw2rDCSw",
    video: true,
  },
  
]
export default news