import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'
import News from '../views/News.vue'
import NewsArticle from '../views/NewsArticle.vue'
import i18n from '../i18n'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/:id',
    name: 'NewsArticle',
    component: NewsArticle
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const defaultTitle = "MYGUARD CO., LTD."
router.beforeEach((to, from, next) => {
  const defaultTitle = i18n.global.t("Title")
  document.title = to.meta.title ? `${to.meta.title} | ${defaultTitle}` : defaultTitle;
  next();
})

export default router
