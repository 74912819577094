<template>
  <div class="news-card">
    <a v-if="n.video" data-bs-toggle="modal" data-bs-target="#videoModal">
        <div class="card">
          <img :src="n.src" class="card-img-top" :alt="n.title" />
          <div class="card-body">
            <h5 class="card-title">{{ n.title }}</h5>
            <p class="time text-primary m-0">{{ n.time }}</p>
            <div class="card-text-area">
              <p class="card-text text-start">{{ n.description }}</p>
            </div>
          </div>
        </div>
      </a>
      <a v-else class="btn" :href="n.link">
        <div class="card">
          <img :src="n.src" class="card-img-top" :alt="n.title" />
          <div class="card-body">
            <h5 class="card-title">{{ n.title }}</h5>
            <p class="time text-primary m-0">{{ n.time }}</p>
            <div class="card-text-area">
              <p class="card-text text-start">{{ n.description }}</p>
            </div>
          </div>
        </div>
      </a>
  </div>
</template>
<script>
export default {
  name: 'NewsCard',
  props: {
    n: Object
  },
}
</script>
<style lang="scss">
  .card {
    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
    .card-body {
      &:hover {
        background-color: var(--bs-light);
      }
      .time {
        font-size: .8rem;
      }
    }
    .card-text-area {
      max-height: 50px;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
</style>