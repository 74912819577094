const banner = {
  "zh-TW": [
    { src: require(`@/assets/images/zh-TW/slide7.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide8.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide9.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
  ],
  "zh-CN": [
    { src: require(`@/assets/images/zh-TW/slide7.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide8.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide9.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
  ],
  en: [
    { src: require(`@/assets/images/zh-TW/slide7.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide8.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide9.jpg`), link: "https://tsklab.com.tw" },
    { src: require(`@/assets/images/zh-TW/slide1.jpg`) },
    { src: require(`@/assets/images/zh-TW/slide2.jpg`) },
  ],
}

export default banner;