<template>
  <VideoModal :title="title" :link="link"></VideoModal>
  <swiper
    slides-per-view="auto"
    :space-between="20"
    :watchSlidesProgress="true"
    :mousewheel="true"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
  >
    <swiper-slide v-for="(n, index) in news" :key="index">
      <NewsCard :n="n" @click="showVideo(n)"></NewsCard>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination } from "swiper";
import VideoModal from "@/components/VideoModal.vue";
import NewsCard from "@/components/NewsCard.vue";
import news from "@/config/news.js"

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

export default {
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      title: "",
      link: "",
      news: news,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    VideoModal,
    NewsCard,
  },
  methods: {
    showVideo(n) {
      this.title = n.title
      this.link = n.link
    }
  }
};
</script>
<style scoped lang="css">
.swiper-slide {
  width: min(25rem, 100%);
}
</style>
