export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遠京企業股份有限公司 MYGUARD CO.,LTD."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遠京擁有超過25年以上的專業技術，多年來致力於美容醫學的新科技，與全球化妝品牌及皮膚醫學機構皆有長期合作的經驗，目前代理美容醫學檢測設備、光學儀器及醫療器材，同時評估客戶需求，提供專業服務。<br>我們的使命是運⽤創新醫療產品及技術，幫助醫師及病患獲得最優質的醫療安全及服務。揮別傳統的模式，我們將創新技術導入美容醫學的專業，並結合行動裝置的應用，省去繁複的步驟，創造客戶互動關係。"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遠京「三大核心價值」：INNOVATION、MOBILE、BEAUTY。"])},
    "value1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
    "ds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司致力於美容醫學專業技術，有別於傳統模式，提供創新科技的應用。"])},
    "value2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE"])},
    "ds2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品結合行動裝置的運用，即時掌握健康資訊，提供多元化的服務與諮詢。"])},
    "value3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEAUTY"])},
    "ds3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制定專屬於你的美容醫學照護計畫。讓「明日美麗，今日可見」！"])}
  },
  "history": {
    "story1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1998年創立遠京企業股份有限公司"])},
    "story2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2003年取得⽇本Scalar公司中/港/台之授權，代理⼀系列之數位光學檢測設備"])},
    "story3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009年⾄2021年負責法國⽪耶法柏⼤藥廠全球⽪膚及頭⽪檢測系統設備供應"])},
    "story4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015年歐洲異位性⽪膚炎基⾦會委託開發PO-SCORAD APP，擁有23國語⾔"])},
    "story5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015年取得荷蘭InnoFaith公司之中/港/台 OBSERV全臉影像分析檢測儀銷售業務"])},
    "story6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年AI情緒美學分析系統開發及專利申請"])},
    "story7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年遠京與OMNIcounsel合作"])},
    "story8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年與中國醫藥⼤學合作技轉-表⾯年輕化量測"])},
    "story8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文獻資料"])},
    "story9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021年OBSERV 全球超過1億張肌膚檢測的信賴"])},
    "story10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年發表OBSERV肌膚旅歷"])},
    "story11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年OBSERV教育平台上線"])},
    "story12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023年OBSERV 520x 發表AI紅棕膚質分析、AI⾯部年輕化分析、AI微表情分析"])},
    "story13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023年取得日本TSK公司之臺灣代理授權"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多資訊"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市龜山區文興路146號3樓之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}