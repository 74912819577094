<template>
  <VideoModal :title="title" :link="link"></VideoModal>
  <div class="news container my-5">
    <h1>{{ $t("News") }}</h1>
    <div class="row">
      <div class="col-lg-4 col-sm-10" v-for="(n, index) in news" :key="index">
        <NewsCard :n="n" @click="showVideo(n)"></NewsCard>
      </div>
    </div>
  </div>
</template>
<script>
import VideoModal from "@/components/VideoModal.vue";
import NewsCard from "@/components/NewsCard.vue";
import news from "@/config/news.js";
export default {
  name: "News",
  components: {
    VideoModal,
    NewsCard,
  },
  data() {
    return {
      title: "",
      link: "",
      news: news,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    showVideo(n) {
      this.title = n.title;
      this.link = n.link;
    },
  },
};
</script>
<style lang=""></style>
