<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <Header></Header>
  <router-view />
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  h1,
  h2,
  h3 {
    color: var(--bs-primary);
  }
}
a {
  text-decoration: none;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  color: white !important;
}

.img-sm {
  max-width: min(500px, 100%);
}
.img-md {
  max-width: min(800px, 100%);
}
.img-max {
  max-width: min(1200px, 100%);
}
.embed-container { 
  position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; 
  } 
.embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;
 }
</style>
