export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MYGUARD CO.,LTD."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MYGUARD Co., Ltd. possesses over 25 years of professional experience and is committed to the medical device and beauty care technology over the long term. MYGUARD not only has a long-term partnership with global cosmetic brands and medical dermatology institutes but acts as the agent  skin & hair diagnostic systems, optical instruments and medical device. And evaluates the needs of the customers, provide professional services.<br>Compared to traditional diagnostic system, MYGUARD introduces innovative technology into aesthetic medicine and cosmetology by combining the application of mobile devices, so you could skip complicated steps and create an interactive relation with customers."])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The three core values of MYGUARD:"])},
    "value1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
    "ds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compared to traditional models, MYGUARD is committed to the skin & hair diagnostic systems in the application of novel innovative technology."])},
    "value2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE"])},
    "ds2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By combining the mobile devices, customers can keep track of their health information and benefit from multivariate services and consultations in real-time."])},
    "value3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEAUTY"])},
    "ds3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your exclusive skin health plans so that “The beauty of tomorrow is visible today”"])}
  },
  "history": {
    "story1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1998- Established in 1998, MYGUARD CO., LTD."])},
    "story2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2003- Authorized by Scalar Corporation in Japan, MYGUARD CO., LTD. acts as an agent for a series of digital optical inspection equipment in China, Hong Kong, and Taiwan."])},
    "story3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009- Responsible for the global supply of skin and scalp detection systems for Pierre Fabre Dermocosmetics from 2009 to 2021."])},
    "story4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015- Developed PO-SCORAD APP commissioned by the European Eczema Foundation, available in 23 languages."])},
    "story5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015- Obtained sales business of OBSERV full-face imaging analysis device from InnoFaith in China, Hong Kong, and Taiwan."])},
    "story6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019- Development and patent application of the AI emotional aesthetic analysis system."])},
    "story7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019- MYGUARD collaborates with OMNIcounsel."])},
    "story8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020- Collaborative technology transfer with China Medical University – AI Facial Rejuvenation measurement. "])},
    "story8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "story9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021- Over 100 million skin analysis sessions of OBSERV worldwide."])},
    "story10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022- Lauched OBSERV Skin Journey"])},
    "story11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022- OBSERV online education platform is launched"])},
    "story12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023- OBSERV 520x releases AI RB analysis. AI facial rejuvenation analysis and AI micro-expressions analysis"])},
    "story13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023- Obtained Taiwan distributor authorization from Japan TSK Laboratory"])}
  },
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市龜山區文興路146號3樓之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}