<template>
  <div class="footer container-sm">
    <p class="my-3">
      Copyright © 2024 Myguard All rights reserved.
      <span class="float-end" v-show="$route.path != '/privacy'"
        ><a href="/privacy">{{ $t("Privacy policy") }}</a></span
      >
    </p>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
