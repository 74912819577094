export const lang = [
  { name: "繁體", lang: "zh-TW" },
  { name: "简体", lang: "zh-CN" },
  { name: "English", lang: "en" },
];

export const tab = [
  { name: "About", to: "#about" },
  { name: "Product", to: "#product" },
  { name: "News", to: "#news" },
  { name: "Contact", to: "#contact" },
];