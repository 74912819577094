<template>
  <div class="home">
    <Carousel :banner="banner[$i18n.locale]"></Carousel>
    <!-- 關於 -->
    <div id="about">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">About</h1>
        <div class="row">
          <div class="col-lg-6 col-sm-10 center">
            <img class="w-100" src="@/assets/images/about.png" />
          </div>
          <div class="col-lg-6 col-sm-10 text-start">
            <p v-html="$t('about.description')"></p>
            <br /><br />
            <p>{{ $t("about.value") }}</p>
            <p>
              <span class="fw-bold text-primary">{{ $t("about.value1") }}</span
              >：{{ $t("about.ds1") }}
            </p>
            <p>
              <span class="fw-bold text-primary">{{ $t("about.value2") }}</span
              >：{{ $t("about.ds2") }}
            </p>
            <p>
              <span class="fw-bold text-primary">{{ $t("about.value3") }}</span
              >：{{ $t("about.ds3") }}
            </p>
          </div>
        </div>
      </div>
    </div>
     <!-- 歷程 -->
    <div id="history">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">Company Calendar</h1>
        <br>
        <div class="row">
          <div class="col-lg-6 col-sm-10 text-start">
            <ul>
              <li>{{ $t("history.story1") }}</li>
              <li>{{ $t("history.story2") }}</li>
              <li>{{ $t("history.story3") }}</li>
              <li>{{ $t("history.story4") }}</li>
              <li>{{ $t("history.story5") }}</li>
              <li>{{ $t("history.story6") }}</li>
              <li>{{ $t("history.story7") }}</li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-10 text-start">
            <ul>
              <li>{{ $t("history.story8") }}<br>({{ $t("history.story8_1") }}：<a href="https://onlinelibrary.wiley.com/doi/10.1111/jocd.14221" target="_blank">https://onlinelibrary.wiley.com/doi/10.1111/jocd.14221</a>)</li>
              <li>{{ $t("history.story9") }}</li>
              <li>{{ $t("history.story10") }}</li>
              <li>{{ $t("history.story11") }}</li>
              <li>{{ $t("history.story12") }}</li>
              <li>{{ $t("history.story13") }}</li>
            </ul>
          </div>
        
        </div>
      </div>
    </div>
     <!-- 產品 -->
    <div id="product" class="bg-light">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">Product</h1>
        <div class="row justify-content-center">
          <div class="col-lg-5 col-sm-10 center" v-for="(p, index) in product1" :key="index">
            <a :href="p.link" target="_blank">
              <img class="w-100" :src="p.src" />
              <a
                class="mt-2 p-2 px-5 btn rounded-pill border border-secondary border-2 btn-outline-secondary fw-bold"
                :href="p.link"
                target="_blank"
                >{{ $t("More Info") }} <i class="fa-solid fa-angles-right"></i
              ></a>
            </a>
          </div>
          <div class="col-lg-5 col-sm-10 center" v-for="(p, index) in product2" :key="index">
            <a :href="p.link" target="_blank">
              <img class="w-100" :src="p.src" />
              <a
                class="mt-2 p-2 px-5 btn rounded-pill border border-blue border-2 btn-outline-blue fw-bold"
                :href="p.link"
                target="_blank"
                >{{ $t("More Info") }} <i class="fa-solid fa-angles-right"></i
              ></a>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 單個產品架構 
    <div id="product" class="bg-light">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">Prdouct</h1>
        <div class="row justify-content-center">
          <div class="col-auto" v-for="(p, index) in product" :key="index">
            <a :href="p.link" target="_blank">
              <img class="w-100" :src="p.src" />
              <a
                class="mt-3 p-2 px-5 btn rounded-pill border border-secondary border-2 btn-outline-secondary fw-bold"
                :href="p.link"
                target="_blank"
                >{{ $t("More Info") }} <i class="fa-solid fa-angles-right"></i
              ></a>
            </a>
          </div>
        </div>
      </div>
    </div>-->
    <!-- 最新消息 -->
    <div id="news">
      <div class="container py-5">
        <h1 class="text-start text-gray fw-bold">News</h1>
        <Swiper></Swiper>
        <router-link
          class="mt-3 p-2 px-5 btn rounded-pill btn-primary fw-bold"
          to="/news"
        >{{ $t("See More") }}</router-link>
      </div>
    </div>
    <!-- 聯絡我們 -->
    <div id="contact" class="bg-light">
      <div class="container py-5 text-start">
        <img class="mb-3" src="@/assets/logo.png" />
        <h3 class="text-black border-bottom border-2">
          遠京企業股份有限公司 MYGUARD CO., LTD.
        </h3>
        <div class="row">
          <div class="col-lg-6 col-sm-10">
            <br />
            <p class="fw-bold">{{ $t("contact.country") }}</p>
            <p>
              <span class="fw-bold">{{ $t("contact.address") }}: </span
              >{{ $t("contact.addressV") }}
            </p>
            <p>
              <span class="fw-bold">{{ $t("contact.phone") }}: </span>
              <a href="tel:+886-3-327-0080">+886 3 327-0080</a>
            </p>
            <p>
              <span class="fw-bold">{{ $t("contact.email") }}: </span>
              <a href="mailto:info@myguard.com">info@myguard.com</a>
            </p>
            <br />
          </div>
          <div class="col-lg-6 col-sm-10">
            <br />
            <p class="fw-bold">Taiwan</p>
            <p>
              <span class="fw-bold">{{ $t("contact.address") }}: </span>3F.-2, No.146,
              Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan
            </p>
            <p>
              <span class="fw-bold">{{ $t("contact.phone") }}: </span>
              <a href="tel:+886-3-327-0080">+886 3 327-0080</a>
            </p>
            <p>
              <span class="fw-bold">{{ $t("contact.email") }}: </span>
              <a href="mailto:info@myguard.com">info@myguard.com</a>
            </p>
          </div>
        </div>
        <iframe
          class="w-100 mt-3"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28913.318916081163!2d121.3655983!3d25.062400699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a77f1ea5bfdb%3A0xf37f0cec253def74!2z6YGg5Lqs5LyB5qWt6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1675846670497!5m2!1szh-TW!2stw"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import Swiper from "@/components/Swiper.vue";
import banner from "@/config/homeBanner.js";

export default {
  name: "Home",
  components: {
    Carousel,
    Swiper,
  },
  data() {
    return {
      banner: banner,
      product1: [
        {
          src: require("@/assets/images/product1.png"),
          link: "https://observ.com.tw/product/520x",
        },
      ],
      product2: [
        {
          src: require("@/assets/images/product2.png"),
          link: "https://tsklab.com.tw",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#contact {
  p {
    margin: 0;
  }
}
</style>
