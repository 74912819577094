<template>
  <div class="news-article">
    <div v-if="n">
      <img class="w-100" :src="n.articleImg" />
      <div class="bg-light">
        <div class="container py-5">
          <h3 class="text-start">
            <span class="bg-dark-gray px-2 mx-2 text-white rounded">News</span>{{ n.title }}
          </h3>
          <p class="float-end">{{ n.time }}</p>
          <div class="bg-white text-start my-5">
            <component :is="comp"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import news from "@/config/news.js";
import { defineAsyncComponent } from "vue";
export default {
  name: "NewsArticle",
  data() {
    return { news: news, n: null };
  },
  computed: {
    comp() {
      return defineAsyncComponent(() => import(`@/components/news/News${this.n.id}.vue`));
    },
  },
  mounted() {
    const n = this.news.find((n) => n.id == this.$route.params.id);
    this.n = n;
  },
};
</script>
<style lang=""></style>
