export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京企业股份有限公司 MYGUARD CO.,LTD."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
  "about": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京拥有超过25年以上的专业技术，多年来致力于美容医学的新科技，与全球化妆品牌及皮肤医学机构皆有长期合作的经验，目前代理美容医学检测设备、光学仪器及医疗器材，同时评估客户需求，提供专业服务。<br>我们的使命是运⽤创新医疗产品及技术，帮助医师及病患获得最优质的医疗安全及服务。挥别传统的模式，我们将创新技术导入美容医学的专业，并结合行动装置的应用，省去繁复的步骤，创造客户互动关系。"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远京「三大核心价值」：INNOVATION、MOBILE、BEAUTY。"])},
    "value1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
    "ds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司致力于美容医学专业技术，有别于传统模式，提供创新科技的应用。"])},
    "value2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE"])},
    "ds2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品结合行动装置的运用，即时掌握健康资讯，提供多元化的服务与咨询。"])},
    "value3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEAUTY"])},
    "ds3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制定专属于你的美容医学照护计画。让「明日美丽，今日可见」！"])}
  },
  "history": {
    "story1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1998年创立远京企业股份有限公司"])},
    "story2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2003年取得⽇本Scalar公司中/港/台之授权，代理⼀系列之数位光学检测设备"])},
    "story3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009年⾄2021年负责法国⽪耶法柏⼤药厂全球⽪肤及头⽪检测系统设备供应"])},
    "story4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015年欧洲异位性⽪肤炎基⾦会委托开发PO-SCORAD APP，拥有23国语⾔"])},
    "story5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015年取得荷兰InnoFaith公司之中/港/台 OBSERV全脸影像分析检测仪销售业务"])},
    "story6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年AI情绪美学分析系统开发及专利申请"])},
    "story7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2019年远京与OMNIcounsel合作"])},
    "story8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年与中国医药⼤学合作技转-表⾯年轻化量测"])},
    "story8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文献资料"])},
    "story9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021年OBSERV 全球超过1亿张肌肤检测的信赖"])},
    "story10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年发表OBSERV肌肤旅历"])},
    "story11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年OBSERV教育平台上线"])},
    "story12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023年OBSERV 520x 发表AI红棕肤质分析、AI⾯部年轻化分析、AI微表情分析"])},
    "story13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023年取得日本TSK公司之台湾代理授权"])}
  },
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃园市龟山区文兴路146号3楼之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多资讯"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私权政策"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}